import React from 'react'
import Toast from '~/components/Toast'
import Dialogs from '~/components/Dialogs'
import PlayerLoader from '~/components/Player/PlayerLoader'

const CommonComponents = () => {
  return (
    <React.Fragment>
      <PlayerLoader />
      <Toast />
      <Dialogs />
    </React.Fragment>
  )
}

export default CommonComponents
