import React from 'react'
import App from 'next/app'
import Head from 'next/head'
import MuiThemeProvider from '@material-ui/styles/ThemeProvider'
import CssBaseline from '@material-ui/core/CssBaseline'
import createStore from '~/store'
import Router from '~/utils/router'
import { pushToRouteHistory } from '~/ducks/routes'
import { init as authenticate } from '~/ducks/auth'
import { clearSearch } from '~/ducks/search'
import withRedux from 'next-redux-wrapper'
import * as Sentry from '@sentry/browser'
import { Provider } from 'react-redux'
import compose from 'recompose/compose'
import { ApolloProvider } from '@apollo/react-components'
import withApolloClient from '~/utils/withApolloClient'
import { pageview as pageviewGtag } from '~/utils/gtag'
import { mainTheme as theme } from '~/theme'
import { appWithTranslation } from '~/i18n'
import CommonComponents from '~/components/CommonComponents'
import { randomizeSeed } from '~/ducks/search'
import 'typeface-montserrat'
import 'typeface-overpass'

class MyApp extends App {
  constructor(props) {
    super(props)
    Sentry.init({ dsn: process.env.SENTRY_DSN })
  }

  static async getInitialProps({ Component, ctx }) {
    let pageProps = {}
    if (Component.getInitialProps) {
      pageProps = (await Component.getInitialProps(ctx)) || {}
    }
    if (!process.browser) {
      // generate and store a random seed for random playlists and lists
      // NOTE: ssr because of caching purpose
      ctx.store.dispatch(randomizeSeed())
    }
    return { pageProps }
  }

  componentDidCatch(error, errorInfo) {
    Sentry.configureScope((scope) => {
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, errorInfo[key])
      })
    })
    Sentry.captureException(error)

    // This is needed to render errors correctly in development / production
    super.componentDidCatch(error, errorInfo)
  }

  componentDidMount() {
    const { store } = this.props
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side')
    if (jssStyles) {
      jssStyles.parentNode.removeChild(jssStyles)
    }
    // authtentication
    store.dispatch(authenticate())
    // save current page as visited pages
    store.dispatch(pushToRouteHistory())
    Router.events.on('routeChangeComplete', this.handleRouteChange)
  }

  componentWillUnmount() {
    Router.events.off('routeChangeComplete', this.handleRouteChange)
  }

  handleRouteChange = (url) => {
    const { store } = this.props
    // save visited pages
    store.dispatch(pushToRouteHistory())
    // clear search if we leave the seach page
    if (url.indexOf('/recherche') === -1) {
      store.dispatch(clearSearch())
    }
    // scroll top
    window.scrollTo(0, 0)
    // Google analytics
    pageviewGtag(url)
  }

  render() {
    const { Component, pageProps, store, apolloClient } = this.props
    return (
      <React.Fragment>
        <Head>
          <title key="title">Mélo</title>
        </Head>
        <MuiThemeProvider theme={theme}>
          {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
          <CssBaseline />
          <Provider store={store}>
            <ApolloProvider client={apolloClient}>
              <Component {...pageProps} />
              <CommonComponents />
            </ApolloProvider>
          </Provider>
        </MuiThemeProvider>
      </React.Fragment>
    )
  }
}

export default compose(
  withApolloClient,
  withRedux(createStore, { debug: false }),
  appWithTranslation
)(MyApp)
