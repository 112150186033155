import React from 'react'
import {
  withStyles,
  createStyles,
  WithStyles,
  Theme,
} from '@material-ui/core/styles'
import Snackbar from '@material-ui/core/Snackbar'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import * as toastActions from '~/ducks/toast'
import green from '@material-ui/core/colors/green'
import amber from '@material-ui/core/colors/amber'
import Types from 'Types'

const styles = (theme: Theme) =>
  createStyles({
    close: {
      padding: theme.spacing(0.5),
    },
    success: {
      backgroundColor: green[600],
    },
    error: {
      backgroundColor: theme.palette.error.dark,
    },
    info: {
      backgroundColor: theme.palette.primary.dark,
    },
    warning: {
      backgroundColor: amber[700],
    },
  })

interface Props extends WithStyles {
  handleClose: () => void
  message: string
  open: boolean
  variant: 'success' | 'error' | 'info' | 'warning'
}

const Toast = ({ classes, handleClose, message, open, variant }: Props) => (
  <Snackbar
    anchorOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    open={open}
    autoHideDuration={6000}
    onClose={handleClose}
    message={<span id="message-id">{message}</span>}
    ContentProps={{ className: variant && classes[variant] }}
    action={[
      <IconButton
        key="close"
        aria-label="Close"
        color="inherit"
        className={classes.close}
        onClick={handleClose}
      >
        <CloseIcon />
      </IconButton>,
    ]}
  />
)

export default compose<Props, {}>(
  withStyles(styles),
  connect(
    (state: Types.RootState) => ({
      open: state.toast.open,
      message: state.toast.message,
      variant: state.toast.variant,
    }),
    {
      handleClose: toastActions.close,
    }
  )
)(Toast)
