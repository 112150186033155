import { fromGlobalId } from 'graphql-relay'
import { UrlObject, format } from 'url'

export default (globalId: string, slug?: string | null) => {
  const { href, as } = getEntityUrl({ globalId, slug })
  return {
    href: format(href),
    as: as.pathname,
  }
}

export const getEntityUrl = ({
  globalId,
  slug,
}: {
  globalId: string
  slug?: string | null
}): {
  href: UrlObject
  as: UrlObject
} => {
  const {
    type,
  }: { type: 'PostList' | 'User' | 'Collection' | string } = fromGlobalId(
    globalId
  )
  switch (type) {
    case 'PostList':
      return {
        href: {
          pathname: `/parcours`,
          query: { slug },
        },
        as: { pathname: `/p/${slug}` },
      }
    case 'User':
      return {
        href: {
          pathname: `/profil`,
          query: { slug },
        },
        as: { pathname: `/profil/${slug}` },
      }
    case 'Collection': {
      return {
        href: {
          pathname: '/collection',
          query: {
            oid: globalId,
          },
        },
        as: {
          pathname: `/collection/${globalId}`,
        },
      }
    }
    // default are notices
    default:
      return {
        href: { pathname: '/notice', query: { slug } },
        as: { pathname: `/notice/${slug}` },
      }
  }
}
