import React from 'react'
import dynamic from 'next/dynamic'
import { useSelector } from '~/reducers'
import { findLast } from 'lodash'
import { parse, format, UrlObject } from 'url'
import { useRouter } from 'next/router'
import { getEntityUrl } from '~/utils/getEntityLink'

const Player = dynamic(() => import('~/components/Player'), {
  ssr: false,
})

const PlayerLoader = () => {
  const showPlayer = useSelector((state) => !!state.player.media)
  const routeHistory = useSelector((state) => state.routes.history)
  const fullscreen = useSelector((state) => state.player.fullscreen)
  const playlistGlobalId = useSelector((state) => state.player.playlistGlobalId)
  const playlistSlug = useSelector((state) => state.player.playlistSlug)
  const router = useRouter()

  React.useEffect(() => {
    // NOTE: it's important to keep in mind what is kept after a CLOSE action
    // (slug, and globalId for now, in order to redirect user to the right page)
    if (!fullscreen && router.pathname === '/player/[oid]') {
      let nextPage:
        | { as: string | UrlObject; href: string | UrlObject }
        | undefined = findLast(
        routeHistory,
        (page: typeof routeHistory[0]) =>
          parse(format(page.href)).pathname !== '/player/[oid]'
      )
      if (!nextPage && playlistGlobalId) {
        nextPage = getEntityUrl({
          globalId: playlistGlobalId,
          slug: playlistSlug,
        })
      }
      nextPage = nextPage || { href: '/', as: '/' }

      let { href, as } = nextPage
      router.replace(href, as)
    }
  }, [
    fullscreen,
    routeHistory,
    showPlayer,
    router,
    playlistGlobalId,
    playlistSlug,
  ])

  if (!showPlayer) {
    return null
  }
  return <Player />
}

export default PlayerLoader
