import React from 'react'
import initApollo from '~/utils/initApollo'
import Head from 'next/head'
import { getDataFromTree } from '@apollo/react-ssr'
import { i18n } from '~/i18n'

export default (App) => {
  return class Apollo extends React.Component {
    static displayName = 'withApollo(App)'

    static async getInitialProps(ctx) {
      const { Component, router, ctx: context } = ctx

      let appProps = {}
      if (App.getInitialProps) {
        appProps = await App.getInitialProps(ctx)
      }
      // Run all GraphQL queries in the component tree
      // and extract the resulting data
      const apollo = initApollo(undefined, {
        getToken: () => context.store.getState().auth.token,
        language: () => (context?.req ? context.req.language : i18n.language),
      })
      if (!process.browser) {
        try {
          // Run all GraphQL queries
          await getDataFromTree(
            <App
              {...appProps}
              Component={Component}
              router={router}
              apolloClient={apollo}
            />
          )
        } catch (error) {
          // Prevent Apollo Client GraphQL errors from crashing SSR.
          // Handle them in components via the data.error prop:
          // https://www.apollographql.com/docs/react/api/react-apollo.html#graphql-query-data-error
          // console.error('Error while running `getDataFromTree`', error)
        }

        // getDataFromTree does not call componentWillUnmount
        // head side effect therefore need to be cleared manually
        Head.rewind()
      }

      // Extract query data from the Apollo store
      const apolloState = apollo.cache.extract()
      return {
        ...appProps,
        apolloState,
      }
    }

    constructor(props) {
      super(props)
      this.apolloClient = initApollo(props.apolloState, {
        getToken: () =>
          window && window.__NEXT_REDUX_STORE__.getState().auth.token,
        language: () => i18n.language,
      })
    }

    render() {
      return <App {...this.props} apolloClient={this.apolloClient} />
    }
  }
}
