import React from 'react'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import { useSelector, useDispatch } from '~/reducers'
import { close, DIALOG_TYPE } from '~/ducks/dialog'

const EventDialog = dynamic(() => import('~/components/EventDialog'))

const Dialogs = () => {
  const router = useRouter()
  const dispatch = useDispatch()
  const dialog = useSelector((state) => state.dialog)

  /** Listen for url change, and close the dialog */
  React.useEffect(() => {
    function handler() {
      dispatch(close())
    }
    router.events.on('routeChangeComplete', handler)
    return () => {
      router.events.off('routeChangeComplete', handler)
    }
  }, [dispatch, router])

  switch (dialog.type) {
    case DIALOG_TYPE.EVENT:
      return <EventDialog {...dialog.params} />
    default:
      return null
  }
}

export default Dialogs
